#pagina-entrar .menu-topo {
  background: #B75EDC;
}

#corpo-entrar {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#logo-colorido {
  width: 225px;
  height: auto;
  margin-top: 60px;
}

#corpo-entrar .texto-area {
  font-size: 18px;
  width: 100%;
  max-width: 600px;
  padding: 0 15px;
}

#form-acesso-usuario {
  width: 300px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form-acesso-usuario .corpo-form,
#form-acesso-usuario .input-group {
  width: 100%;
}

#form-acesso-usuario label {
  left: 15px;
}
