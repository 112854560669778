#pagina-cadastro-especialista .menu-topo {
  background: #B75EDC;
}

#cadastro-especialistas {
  width: 100%;
  min-height: calc(90vh - 135px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 30px;
}

#cadastro-especialistas label {
  left: 15px;
}

#fluxo-cadastro {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
}

.passo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #eee;
  transition: background .2s ease-in;
}

.barra-passo {
  width: 12px;
  height: 5px;
  background: #eee;
  transition: background .2s ease-in;
}

.passo.ativo {
  background: #2FA884;
}

.barra-passo.ativo {
  background: #2FA884;
}

#cadastro-dados-especialista {
  width: 750px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#porta-forms {
  width: 100%;
}

.topo-form {
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.topo-form p {
  text-align: center;
  font-family: "NexaReg";
  font-size: 15px;
  color: #25234d;
  flex: 1;
}

.corpo-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.corpo-form .input-field {
  width: calc(100% - 8px);
  margin: 15px auto;
}

.corpo-form input {
  height: 44px;
}

.obs {
  font-size: 11px;
  font-family: "NexaReg";
  color: #909090;
}

#cadastro-especialistas .msg-retorno {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
}

#porta-forms .input-group {
  width: 100%;
}

#form-cadastro-usuario {
  width: 350px;
  margin: 0 auto;
}

#form-cadastro-usuario .input-group {
  width: 300px;
}

#form-cadastro-usuario label {
  left: 15px;
}

#form-cadastro-usuario .msg-retorno {
  margin-top: 0;
  width: calc(100% - 54px);
  margin-left: 0;
}

.form-dados {
  width: 350px;
  margin: 0 auto;
}

.porta-opcoes,
.opcao {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.opcao {
  margin-right: 25px;
}

.porta-opcoes input {
  height: 24px;
  margin-top: -2px;
}

.porta-opcoes .texto-area {
  font-size: 18px;
}

#porta-forms textarea {
  min-height: 80px;
  height: 80px;
  max-height: 150px;
  padding: 10px;
  text-indent: 0;
  width: calc(100% - 32px);
  max-width: calc(100% - 32px);
  min-width: calc(100% - 32px);
}

::placeholder {
    color: #b6b6b6;
    opacity: 1;
}

.porta-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.porta-inputs .input-group {
  width: 47% !important;
}

.opcao-selecao {
  padding: 0 20px;
  height: 44px;
  border: 1px solid #ccc;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background .15s ease-in, color .15s ease-in;
}

.opcao-selecao:hover {
  background: #f3f3f3;
}

.opcao-selecao .texto-area {
  font-size: 14px;
}

.opcao-selecao.ativo {
  background: #2fa783;
  border-color: #2fa783;
}

.opcao-selecao.ativo p {
  color: #fff;
}

.opcao-tipo-local {
  justify-content: flex-start;
}

.bloco-especialidade {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}

.bloco-especialidade .corpo-bloco-especialidade {
  display: none;
}

.bloco-especialidade.aberto .corpo-bloco-especialidade {
  display: block;
}

.corpo-form select {
    height: 48px;
    width: 100%;
}

.adicao-pacotes {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-top: -10px;
  transition: opacity .2s ease-in;
}

.adicao-pacotes:hover {
  opacity: .75;
}

.adicao-pacotes img {
  width: 20px;
  height: auto;
  margin-right: 3px;
  margin-top: -3px;
}

.salva-especialidade {
  width: 225px;
  color: #6426dd;
  border: 1px solid #6426dd;
  background: #fff;
  margin-bottom: 15px;
}

#campo-numero,
#campo-agencia {
  width: 30% !important;
}

#campo-complemento,
#campo-num-conta {
  width: 65% !important;
}

#campo-cidade {
  width: 70% !important;
}

#campo-uf {
  width: 25% !important;
}

.expande-bloco {
  cursor: pointer;
  font-size: 25px;
  padding: 10px;
}

.input-group select {
  width: 100% !important;
}

.react-confirm-alert-button-group > button {
  background: #6b0099 !important;
}

#campo-foto-perfil {
  display: none;
}

.bloco-preview-arquivo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.preview-arquivo {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background-color: #d7f4eb;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.botao-upload {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #ffc727;
  position: absolute;
  right: -8px;
  bottom: -8px;
  cursor: pointer;
  transition: background .15s ease-in;
  box-shadow: 0 .5px 2px 0 rgba(0,0,0,.25);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.botao-upload:hover {
  background: #ffd666;
}

.foto-perfil {
  height: 60px;
  width: 60px;
  border-radius: 5px;
  object-fit: cover;
  overflow: hidden;
  cursor: zoom-in;
}

.botao-info {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #6b0099;
  position: absolute;
  right: 10px;
  top: -12px;
  cursor: pointer;
  box-shadow: 0 .5px 2px 0 rgba(0,0,0,.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.botao-info img {
  width: 14px;
  height: auto;
}

@media only screen and (max-width: 750px) {

#cadastro-dados-especialista {
  width: 100%;
}

}

@media only screen and (max-width: 450px) {

.react-confirm-alert-body {
    width: calc(100% - 20px)!important;
    padding: 10px !important;
}

#cadastro-dados-especialista {
  width: calc(100% - 30px);
}

}

@media only screen and (max-width: 350px) {

#form-cadastro-usuario, .form-dados {
  width: 100%;
}

}
