@font-face {
  font-family: 'MyriadPro-Reg';
  src: url('./fontes/Myriad-Pro-Regular.woff');
  font-display: swap;
}

@font-face {
  font-family: 'NexaBold';
  src: url('./fontes/Nexa-XBold.woff');
  font-display: swap;
}

@font-face {
  font-family: 'NexaReg';
  src: url('./fontes/nexaregular.woff');
  font-display: swap;
}

a {
  text-decoration: none;
  color: #2196f3;
}

.botao-verde {
  background: #09df9f;
  color: #171718;
}

.botao-verde:hover {
  background: #23f6b6;
}

.titulo-hero {
  font-size: 45px;
  font-family: 'NexaBold';
  line-height: 0.85;
  word-break: break-word;
}

.subtitulo-hero {
  font-size: 34px;
  padding-top: 5px;
  font-family: 'NexaReg';
  line-height: 0;
  word-break: break-word;
}

.texto-hero {
  font-size: 16px;
  margin-top: 10px;
  font-family: 'NexaReg';
}

.titulo-area {
  font-family: 'NexaReg';
  color: #6422db;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 3px;
}

.subtitulo-area {
  font-size: 35px;
  letter-spacing: -0.4px;
  color: #25234d;
  margin: 10px 0 25px;
  font-family: 'MyriadPro-Reg';
}

.texto-area {
  font-size: 16px;
  color: #25234d;
  opacity: 0.9;
  font-family: 'MyriadPro-Reg';
}

.texto-destacado {
  font-weight: 600;
}

.botao-area {
  width: 225px;
  background: #ffc727;
  color: #171718;
  margin-top: 10px;
}

.botao-area:hover {
  background: #ffd666;
}

.texto-descricao {
  line-height: 1.5;
}

.item-servico {
  box-shadow: 0 30px 70px 0 rgba(240, 241, 246, 0.5);
  background: #fff;
  padding: 25px;
  width: 200px;
}

.titulo-item {
  font-family: 'NexaReg';
  font-weight: 600;
  font-size: 18px;
  color: #25234d;
  margin-top: -10px;
}

.texto-item {
  font-family: 'MyriadPro-Reg';
  font-size: 15px;
  color: #25234d;
  opacity: 0.85;
  margin-top: -15px;
}

#bloco-aviso {
  position: fixed;
  bottom: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  width: calc(100% - 20px);
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

#porta-aviso {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
}

.carregamento-inicial {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.porta-botoes-download-app {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.botao-download-app {
  margin-right: 20px;
}

.img-botao-download-app img {
  width: 150px;
  height: auto;
}

.alice-carousel__stage {
  margin-top: 15px !important;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: #757575 !important;
  line-height: 0 !important;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  content: url('./assets/images/buttons/arrow.png') !important;
}

.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.2 !important;
}

.alice-carousel__next-btn-item {
  transform: rotateZ(180deg);
}

/*****************************
clientes
*****************************/
.clientes {
  margin-top: 80px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.linha-clientes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-clientes {
  width: 120px;
  height: auto;
  margin: 10px 15px;
}

.input-group {
  position: relative;
}

label {
  position: absolute;
  font-size: 13px;
  font-family: 'NexaReg';
  color: #6e6e6e;
  top: 7px;
  left: 27px;
  background: #fff;
  padding: 0 2px;
  z-index: 10;
}

.input-field {
  font-size: 15px;
  color: #46189a;
  letter-spacing: -0.25px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: calc(100% - 32px);
  margin: 15px;
  text-indent: 12px;
  height: 44px;
}

.input-field:focus {
  border: 1px solid #25234d;
}

select {
  height: 48px !important;
  width: calc(100% - 24px) !important;
}

.porta-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  text-align: left;
}

.porta-checkbox input {
  margin-right: 8px;
  margin-top: -2px;
}

.porta-checkbox .texto-area {
  line-height: 1.2;
}

.msg-retorno {
  width: calc(100% - 40px);
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  padding: 2px 5px;
  margin: 0 auto;
}

.msg-retorno:after {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  content: 'X';
  color: #fff;
  font-size: 7.5px;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.msg-retorno p {
  padding: 0 5px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-family: 'NexaReg';
}

.msg-sucesso {
  background: #2fa884;
}

.msg-erro {
  background: #fa464f;
}

.erro-campo {
  width: 26px;
  height: auto;
  position: absolute;
  right: 15px;
  top: 26px;
}

/*****************************
área de acesso e cadastro
*****************************/
#exibe-senha {
  position: absolute;
  padding: 10px;
  right: 8px;
  top: 18px;
  cursor: pointer;
}

#exibe-senha img {
  width: 24px;
  height: auto;
}

.botao-acao {
  width: 225px;
  background: #6b0099;
  color: #fff;
  margin-top: 5px;
}

.botao-acao:hover {
  background: #8f00cc;
}

.seta-retorno {
  background: #fff;
  transition: background 0.2s ease-in;
}

.seta-retorno:hover {
  background: #f3f3f3;
}

.seta-retorno img {
  width: 36px;
  height: auto;
}

@media only screen and (max-width: 990px) {
  .titulo-hero {
    font-size: 42px;
  }

  .subtitulo-hero {
    font-size: 30px;
  }

  .texto-hero {
    font-size: 15px;
  }

  .titulo-area {
    font-size: 12px;
    letter-spacing: 2.5px;
  }

  .subtitulo-area {
    font-size: 32px;
  }

  .texto-area {
    font-size: 15px;
  }
}

@media only screen and (max-width: 600px) {
  #porta-aviso {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 450px) {
  #texto-hero-area {
    width: 100%;
  }

  .titulo-hero {
    font-size: 36px;
    line-height: 1;
  }

  .subtitulo-hero {
    font-size: 26px;
    line-height: 1;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 375px) {
  .porta-botoes-download-app {
    flex-direction: column;
  }

  .botao-download-app {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .img-botao-download-app img {
    width: 175px;
  }
}
