.footer {
  background-image: url("../imagens/bg-footer.webp");
  background-image: -webkit-image-set(url("../imagens/bg-footer.png") 1x);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.conteudo-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 100px);
  margin-left: 100px;
  padding-top: 30px;
}

.conteudo-footer .menu-links {
  margin: 0 50px;
  font-size: 16px;
  font-family: "MyriadPro-Reg";
  display: flex;
  flex-direction: row;
  list-style: none;
}

.coluna-links {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.coluna-links li {
  margin-bottom: 18px;
}

#link-rodape-cadastro {
  display: none;
}

.social-links {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
}

.social-links li {
  margin-right: 10px;
}

.social-links li a {
  transition: background .15s ease-in;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(0, 217, 219, .2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links li a:hover {
  background: rgba(0, 217, 219, .8);
}

.icone-social {
  height: 16px;
  width: auto;
}

.porta-botoes-download-app-rodape {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 15px;
}

.porta-botoes-download-app-rodape .botao-download-app {
  margin-right: 15px;
  margin-bottom: 10px;
}

#linha-direitos {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 25px 0 8px 100px;
}

#linha-direitos li {
  margin-right: 50px;
  font-family: "NexaReg";
  font-size: 12.5px;
  color: #fff;
}

#linha-direitos li:last-child {
  margin-right: 0;
}

#linha-direitos li a {
  padding: 5px 8px;
  color: #fff;
}

#linha-direitos li a:hover {
  color: #e0d3f8;
}

@media only screen and (max-width: 990px) {

.footer {
  height: auto;
  padding-top: 160px;
}

.conteudo-footer {
  margin-left: 0;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
}

.conteudo-footer .menu-links {
  margin-top: 40px;
}

.conteudo-footer .menu-links li {
  text-align: left;
}

.conteudo-footer .menu-links li a {
  padding: 5px 0;
}

#link-rodape-cadastro {
  display: block;
}

.porta-botoes-download-app-rodape {
  align-items: center;
}

.porta-botoes-download-app-rodape .botao-download-app,
.porta-botoes-download-app-rodape .botao-download-app img {
  margin-right: 0;
}

#linha-direitos {
  margin: 25px auto;
  margin-bottom: 8px;
}

}

@media only screen and (max-width: 800px) {

.footer {
  background-position: center;
}

.conteudo-footer {
  padding-top: 80px;
  text-align: left;
  align-items: flex-start;
  width: auto;
  margin: 0 auto;
}

.conteudo-footer .menu-links {
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  margin-top: 35px;
}

.coluna-links {
  padding-left: 0;
}

.social-links li:last-child {
    margin-right: 0;
}

#linha-direitos {
  margin: 0 auto;
  margin-top: 25px;
}

#linha-direitos li {
  margin-bottom: 12px;
}

#linha-direitos li:first-child {
  margin-left: 15px;
}

#linha-direitos li a {
  padding: 5px 15px;
}

}

@media only screen and (max-width: 450px) {

.logo-menu {
  width: 120px;
}

.conteudo-footer .menu-links {
  font-size: 14.5px;
}

.social-links {
    margin-right: 0;
}

}