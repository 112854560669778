#modal-cropper {
	position: fixed;
	z-index: 8888;
	width: 100%;
	height: 100vh;
}

#fundo-modal-cropper {
	position: absolute;
	z-index: 8889;
	width: 100%;
	height: 100vh;
	background: rgba(0,0,0,.4);
}

#topo-modal-cropper {
	height: 60px;
	position: relative;
	z-index: 8890;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
}

#fecha-modal-cropper {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-weight: bold;
	color: #fff;
	margin-right: 15px;
	cursor: pointer;
	transition: opacity .2s;
}

#fecha-modal-cropper:hover {
	opacity: .7;
}

#porta-foto-cropper {
	width: 100%;
	position: relative;
	z-index: 8890;
}

#rodape-modal-cropper {
	background: #fff;
	height: 60px;
	position: relative;
	z-index: 8890;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}