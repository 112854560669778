.pagina-blog .menu-topo,
.artigo .menu-topo {
  background: #B75EDC;
}

.corpo-blog {
  margin: 40px 20px;
  margin-top: 80px;
}

.porta-destaques {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bloco-destaque {
  background: #ddd;
  border-radius: 15px;
  box-shadow: 0px 4px 10px 0px rgba(0,0,0,.15);
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
}

.bloco-destaque:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-image: linear-gradient(transparent, rgba(0,0,0,.5));
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}

.bloco-destaque img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
  position: absolute;
  z-index: 1;
  transition: transform .25s ease-in;
}

.bloco-destaque:hover img {
  transform: scale(1.1) rotate(-2deg);
}

.bloco-detalhes-post {
  position: relative;
  z-index: 12;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}

.bloco-detalhes-post h2 {
  font-family: "NexaReg";
  font-size: 25px;
  text-align: center;
  margin-bottom: 5px;
  position: relative;
  padding: 0 10px;
}

.bloco-detalhes-post h2:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -1px;
  background: #fff;
  transition: width .25s ease-in;
}

.bloco-destaque:hover .bloco-detalhes-post h2:before {
  width: calc(100% - 20px);
}

.bloco-detalhes-post span {
  font-size: 13px;
  text-align: center;
}

.detalhes-extra-post {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.destaque-maior {
  width: 65%;
  height: 80vh;
}

.carrega-maior {
  width: 65% !important; 
  height: 80vh !important; 
  border-radius: 15px;
}

.porta-destaques-menores {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.destaque-menor {
  width: 100%;
  height: 37vh;
}

.carrega-menor {
  width: 100% !important;
  height: 37vh !important;
  border-radius: 15px;
}

.destaque-menor h2 {
  font-size: 20px;
}

.destaque-medio {
  width: 30%;
  height: 80vh;
  margin-right: 20px;
}

.carrega-medio {
  width: 30% !important;
  height: 80vh !important;
  border-radius: 15px;
  margin-right: 20px;
}

.data-post {
  text-transform: capitalize;
}

.destaque-medio h2 {
  font-size: 22px;
}

.porta-artigos-selecionados {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.porta-artigos-selecionados .destaque-medio:last-child,
.porta-artigos-selecionados .carrega-medio:last-child {
  margin-right: 0;
}

.postagens-selecionadas {
  margin: 40px 0;
}

.bloco-postagens {
  margin: 60px auto;
  margin-bottom: 0;
  width: 100%;
}

.porta-postagens {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.post {
  margin-bottom: 260px;
  width: 47.5%;
}

/*.porta-postagens .post:last-child {
  margin-bottom: 0;
}*/

.post .bloco-destaque {
  height: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.post h3 {
  font-size: 20px;
  color: #fff;
  font-family: "NexaReg";
  margin-bottom: 10px;
  position: relative;
  padding: 0 15px;
  z-index: 15;
}

.texto-post {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.texto-post p {
  padding: 0 20px;
}

.texto-post .data-post {
  opacity: .6;
  font-size: 14px;
}

.porta-artigo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  margin-top: 100px;
  width:  calc(100% - 30px);
  max-width: 990px;
}

.corpo-artigo {
  width: calc(100% - 30px);
  max-width: 600px;
  padding: 0 15px;
  min-height: 58vh;
  font-family: "MyriadPro-Reg";
  position: relative;
  z-index: 12;
  margin-top: -100px;
}

.corpo-artigo h1,
.corpo-artigo h2 {
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 0;
  letter-spacing: -.4px;
  color: #25234d;
}

.corpo-artigo p:not(:empty),
.corpo-artigo li {
  padding: 15px;
  padding-top: 0;
  font-size: 18px;
  color: #25234d;
  opacity: .9;
}

.corpo-artigo h3,
.corpo-artigo h4 {
  opacity: .95;
  font-size: 20px;
  color: #25234d;
}

.corpo-artigo h3 {
  font-size: 22px;
}

.corpo-artigo li {
  padding-bottom: 8px;
}

.capa-artigo {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 4;
  box-shadow: 0 5px 30px 5px rgba(0,0,0,.15);
  border-radius: 20px;
  background: transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.capa-artigo img {
  height: auto;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.cabecalho-artigo {
  background: #fff;
  padding-top: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bloco-info-artigo {
  margin: 0 15px; 
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  flex-wrap: wrap;
  width: calc(100% - 30px);
}

.porta-info-artigo, 
.porta-detalhes-artigo {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.porta-foto-autor {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .05);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 5px;
}

.porta-foto-autor img {
  width: 45px;
  object-fit: cover;
}

#nome-autor {
  padding-bottom: 0;
  padding-left: 0;
}

.porta-detalhes-artigo img {
  height: 16px;
  width: auto;
  margin: -2px 4px 0 0;
}

.porta-detalhes-artigo span {
  font-size: 16px;
  color: #25234d;
  opacity: .8;
  margin: 2px 0;
}

@media only screen and (max-width: 990px) {

.destaque-maior, .carrega-maior {
  width: 100%;
  height: 65vh;
}

.porta-destaques-menores {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.destaque-menor, .carrega-menor {
  height: 45vh;
  width: 47.5%;
}

.bloco-detalhes-post h2 {
  font-size: 23px;
}

.destaque-menor h2 {
  font-size: 18px;
}

.porta-artigos-selecionados {
  justify-content: space-between;
}

.destaque-medio, .carrega-medio {
  width: 47.5%;
  height: 60vh;
  margin-right: 0;
}

.destaque-medio h2 {
  font-size: 20px;
}

}

@media only screen and (max-width: 600px) {

.destaque-maior, .carrega-maior {
  height: 40vh;
}

.destaque-menor, .carrega-menor, .destaque-medio, .carrega-medio {
  height: 40vh;
  width: 100%;
}

.bloco-detalhes-post h2 {
  font-size: 21px;
}

.destaque-menor h2 {
  font-size: 16px;
}

.destaque-medio h2 {
  font-size: 18px;
}

.post {
  width: 100%;
}

.porta-artigo {
  width:  calc(100% - 5px);
}

.bloco-info-artigo {
  margin: 0 7.5px; 
  width: calc(100% - 15px);
}

.corpo-artigo {
  width: calc(100% - 10px);
  padding: 0 7.5px;
}

.corpo-artigo h1,
.corpo-artigo h2 {
  padding: 12px 7.5px 0 7.5px;
}

.corpo-artigo p:not(:empty),
.corpo-artigo li {
  padding: 0 7.5px 15px 7.5px;
}

}

@media only screen and (max-width: 375px) {

.porta-detalhes-artigo {
  margin-top: 10px;
}

.porta-info-artigo {
  margin-bottom: 5px;
}

}
