body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body.sem-rolagem {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 0;
  border: 0;
  height: 50px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'MyriadPro-Reg';
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.15s ease-in;
}

p {
  line-height: 1.5;
}
