#pagina-erro {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

#pagina-erro .menu-topo {
  background: #B75EDC;
}

#bloco-nao-encontrada {
	display: flex;
	justify-content: center;
	width: calc(100% - 20px);
	margin: 60px 10px;
}

#conteudo-erro {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	max-width: 500px;
	width: 100%;
}

#bloco-nao-encontrada h2 {
	margin-bottom: 0px;
}

#apresenta-animacao {
	margin-bottom: -80px;
}

.porta-links {
	margin-top: -80px;
	position: relative;
	z-index: 10;
}

#porta-menus-erro {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.menu-erro {
	padding-left: 0;
	list-style: none;
	width: 180px;
	margin: 0;
}

.menu-erro li {
	text-align: left;
	margin-bottom: 15px;
}

.menu-erro:last-child li {
	text-align: right;
}

.menu-erro li a {
	padding: 5px 0;
	padding-right: 15px;
}

.menu-erro:last-child li a {
	padding: 5px 0;
	padding-left: 15px;
}
