.menu-topo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 30px);
  margin: 0 auto;
  padding: 15px;
  position: relative;
}

#dropdown-menu-topo {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1.5px 5px 0 rgba(0,0,0,.15);
  list-style: none;
  padding-left: 0;
  position: absolute;
  z-index: 750;
  width: 150px;
  right: 0;
  top: 30px;
}

#dropdown-menu-topo li {
  width: calc(100% - 20px);
  padding: 10px 0;
  margin: 0 10px;
  cursor: pointer;
  transition: opacity .15s ease-in;
}

#dropdown-menu-topo li:hover {
  opacity: .5;
}

#dropdown-menu-topo li a {
  font-size: 14px;
  color: #25234d !important;
  opacity: .8;
  font-family: "MyriadPro-Reg";
}

#dropdown-menu-topo li:not(:last-child) {
  margin-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}

.logo-menu {
  width: 180px;
  height: auto;
}

.menu-links {
  margin: 0 100px;
  font-size: 16px;
  font-family: "MyriadPro-Reg";
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
}

.menu-links li {
  cursor: pointer;
  margin-right: 50px;
  transition: color .2s ease-in;
  padding: 5px 0;
}

.menu-links li a {
  color: #fff;
}

.menu-links li a:hover {
  color: #e0d3f8;
}

.menu-links .ativo, 
.menu-links .ativo a,
.menu-links .ativo a:hover {
  /*cursor: none;*/
  /*color: #85fad7;*/
  color: #f5f589;
}

.menu-links li:last-child {
  margin-right: 0;
}

#link-entrar{
  display: none;
}

.botao-branco {
  width: 100px;
  background: #fff;
  color: #6426dd;
}

.botao-branco:hover {
  background: #f5f5f5;
}

#botao-servicos {
  display: none;
}

.botao-menu {
  width: 48px;
  height: 48px;
  display: none;
  background: transparent;
}

.botao-menu img {
  width: 28px;
  height: auto;
}

@media only screen and (max-width: 990px) {

.logo-menu {
  width: 150px;
}

.menu-links {
  margin: 0 20px;
  font-size: 15px;
}

.menu-links li {
  margin-right: 30px;
}

}

@media only screen and (max-width: 800px) {

.link-topo {
  display: none;
}

#botao-entrar {
  margin-right: 10px;
}

.botao-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

}

@media only screen and (max-width: 600px) {

.menu-links {
  display: none;
}

.botao-branco {
  width: 70px;
  font-size: 12px;
  height: 36px;
}

#botao-servicos {
  display: block;
}

#botao-entrar {
  margin-right: 0;
}

}

@media only screen and (max-width: 550px) {

#link-quem-somos {
  display: none;
}

}

@media only screen and (max-width: 450px) {

.menu-topo {
  width: calc(100% - 15px);
  padding: 15px 7.5px;
}

#botao-servicos {
  display: none;
}

}
