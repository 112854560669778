#menu-mobile {
  display: none;
}

#fundo-menu-mobile {
  border-radius: 50%;
  width: 0;
  height: 0;
  position: fixed;
  top: 15px;
  right: 2.875rem;
  background: #B39DDB;
  opacity: 1;
  z-index: 10000;
  -webkit-transition: all .4s cubic-bezier(0.000, 0.995, 0.990, 1.000);
          transition: all .4s cubic-bezier(0.000, 0.995, 0.990, 1.000);
  overflow-y: hidden;
}

#fundo-menu-mobile.expandido {
  width: 200vw;
  height: 200vh;
  top: -50vh;
  right: -50vw;
}

#menu-mobile-links {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10001;
  -webkit-transition: all .4s cubic-bezier(0.000, 0.995, 0.990, 1.000);
  transition: all .4s cubic-bezier(0.000, 0.995, 0.990, 1.000);
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-mobile-links.expandido {
  width: 100vw;
  height: 100vh;
}

.fecha-menu {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #46189a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
}

.fecha-menu span {
  color: #46189a;
}

#mobile-links {
    list-style: none;
    padding-left: 0;
    margin-top: 100px;
}

#mobile-links li {
  margin: 0 25px;
  margin-top: 10px;
  padding: 15px 0;
  font-family: "NexaReg";
  font-size: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255,255,255,.4);
}

#mobile-links li a {
  color: #46189a;
}

#mobile-links .ativo {
  color: #fff;
}

@media only screen and (max-width: 800px) {

#menu-mobile {
  display: block;
}

}

@media only screen and (max-width: 450px) {

#mobile-links li {
  font-size: 16px;
}
  
}